
import React from "react";
import { Link } from "react-router-dom";
import { AnchorScroll } from "helper";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
} from "reactstrap";

import { FiMenu } from 'react-icons/fi';
import { FaTimes, FaTwitter, FaDiscord } from 'react-icons/fa'

export default function IndexNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [collapseOut, setCollapseOut] = React.useState("");

  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };
  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };
  const onCollapseExited = () => {
    setCollapseOut("");
  };

  return (

    <div className="navbar pr-0 pl-0" id="home">
      <Navbar expand="xl" className="py-0">
        <Row className="w-100">
          <Col lg="12" xl="2" className="px-0 px-xl-3">
            <div className="d-flex w-100 justify-content-between ">

              <NavbarBrand to="/" tag={Link} id="navbar-brand" onClick={() => AnchorScroll()}>
                <div className="d-flex align-items-center justify-content-center ">
                  <img src={require('../../assets/img/Ads_images/Logo.png').default} height={150} width={150} className="img-fluid ml-lg-5 ml-0" />
                </div>
              </NavbarBrand>

              <button
                aria-expanded={collapseOpen}
                className="navbar-toggler navbar-toggler"
                onClick={toggleCollapse}
              >
                <FiMenu className="text-dark" size="24" />
              </button>
            </div>
          </Col>
          <Col lg="4" xl="10" className="pr-0 d-flex d-flex justify-content-center align-items-center ">
            <Collapse
              className={"ml-4 bg-black justify-content-center align-items-center " + collapseOut}
              navbar
              isOpen={collapseOpen}
              onExiting={onCollapseExiting}
              onExited={onCollapseExited}
            >

              <Row className="navbar-collapse-header">
                <Col className="collapse-close text-right d-flex justify-content-end align-items-end" xs="12">
                  <button
                    aria-expanded={collapseOpen}
                    className="navbar-toggler"
                    onClick={toggleCollapse}
                  >
                    <FaTimes className="pf-text-secondary" color="white" size="24" />
                  </button>

                </Col>
              </Row>


              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column flex-xl-row w-100 justify-content-center  mobile-menu nav-text">
                  <Nav navbar className="nav-text-size ">
                    <NavItem className="p-2">
                      <NavLink
                        data-placement="bottom"
                        rel="noopener noreferrer"
                        href="/" className="mr-2" >
                        HOME
                      </NavLink>
                    </NavItem>
                    <NavItem className="p-2">
                      <NavLink
                        data-placement="bottom"
                        rel="noopener noreferrer"
                        href="/#about" className="mr-2">
                        ABOUT
                      </NavLink>
                    </NavItem>
                    <NavItem className="p-2">
                      <NavLink
                        data-placement="bottom"
                        rel="noopener noreferrer"
                        href="/#features" className="mr-2" >
                        FEATURES
                      </NavLink>
                    </NavItem>
                    <NavItem className="p-2">
                      <NavLink
                        data-placement="bottom"
                        rel="noopener noreferrer"
                        href="/#pricing" className="mr-2" >
                        PRICING
                      </NavLink>
                    </NavItem>
                    <NavItem className="p-2">
                      <NavLink
                        data-placement="bottom"
                        rel="noopener noreferrer"
                        href="/#faqs" className="mr-2" >
                        FAQS
                      </NavLink>
                    </NavItem>
                    <NavItem className="p-2">
                      <NavLink
                        data-placement="bottom"
                        rel="noopener noreferrer"
                        href="/#contact" className="mr-2 " >
                        CONTACT
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <Row>
                    <Col className="d-flex flex-lg-row flex-column align-items-lg-center align-items-start  justify-content-start pl-xl-5 pl-2 ml-xl-5 ml-2">
                      <div className=" pt-4 pt-lg-0 pb-4 pb-lg-0 nav-text-size ml-2">
                        <h5 className="mb-0">LOGIN</h5>
                      </div>

                      <div className="my-3 my-xl-0 ml-lg-5 ml-2 text-nowrap">
                        <a href="#" target="_blank"><button className=" py-2 px-4 red-button "><span className="">SIGN UP</span></button></a>
                      </div>
                    </Col>
                  </Row>

                </div>
              </div>
            </Collapse>
          </Col>
        </Row>
      </Navbar>
    </div>

  );
}
