import React from 'react'
import { Col, Row, Input, Container } from 'reactstrap';

const Contact = () => {
    return (
        <>
            <section id="contact">
                <Container>
                    <Row className=" mt-4 ml-2 mr-2" >

                        <Col lg="6" md="12" data-aos="fade-left">
                            <div className="d-flex justify-content-center align-items-center " >
                                <img src={require("../../assets/img/Ads_images/Support.png").default} alt="img" className="img-fluid px-3" height={"100%"} width={"100%"} />
                            </div>
                        </Col>
                        <Col lg="6" md="12" data-aos="fade-right">
                            <div className="d-lg-block d-none">
                                <div className="  support-text d-flex flex-column justify-content-lg-start justify-content-center align-items-center  align-items-lg-start" >
                                    <h3 className='mb-0'>We've been waiting</h3><h3 className='mb-0 text-lg-left text-center'> for you</h3>

                                    <p className='py-3'>We want to hear from you. Let us know how we can help.</p>
                                </div>
                            </div>
                            <div className="d-block d-lg-none">
                                <div className=" pt-5 pt-lg-0  support-text d-flex flex-column  justify-content-center align-items-center  " >
                                    <h3 className='mb-0 text-center'>We've been waiting for you</h3>

                                    <p className='py-3 text-center'>We want to hear from you. Let us know how we can help.</p>
                                </div>
                            </div>
                            <div className='mb-2'>
                                <Input
                                    id="Name"
                                    name="name"
                                    placeholder="Name"
                                    type="text"

                                />
                            </div>
                            <div className='mb-2'>
                                <Input
                                    id="Email"
                                    name="email"
                                    placeholder="Email"
                                    type="email"

                                />
                            </div>
                            <div className='mb-2'>
                                <Input
                                    id="Phone"
                                    name="Phone"
                                    placeholder="Phone"
                                    type="text"
    
                                />
                            </div>
                            <div className='mb-2'>
                                <Input
                                    id="Text"
                                    name="text"
                                    placeholder="  Message"
                                    type="textarea"
                                    className='text-area'
                                />
                            </div>

                            <button className='red-button submit-button px-5 py-3'>
                                <h6 className='mb-0'>Submit
                                    <span><img src={require("../../assets/img/Ads_images/white-arrow.png").default} alt="img" className="img-fluid ml-2" /></span></h6>
                            </button>
                        </Col>

                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Contact