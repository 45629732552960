import React from 'react'
import { Row, Col, Container } from "reactstrap"

const About = () => {
    return (
        <>
            <section className='mt-lg-5 mt-0' data-aos="zoom-out" >
                <div className=' pt-lg-5 pt-0 mt-lg-5 mt-0' >
                    <Row className='mt-lg-5 mt-0 pt-sm-5 pt-md-0 pt-0 pt-lg-5'>
                        <Col lg="12" md="12" className='py-lg-5 py-0 brand-top-space' >
                            <div className='mac-brand  d-md-block d-none mt-md-0  mt-sm-5 mt-0 pt-lg-5 pt-0'>
                                <img src={require("../../assets/img/Ads_images/about-logos.png").default} height="100%" width="100%" alt="img" className="img-fluid my-lg-0 my-md-5 pt-md-5 pt-lg-0" />
                            </div>
                        </Col>
                    </Row>
                    <Row id='about'>
                        <Col lg="2" md="2" className=''></Col>
                        <Col lg="8" md="8" className='py-2 text-center' >

                            <div className='py-lg-5 py-0 pt-5 about-text d-lg-block d-none'>
                                <div className='mb-2'>
                                    <h3 className='mb-0  text-center '>
                                        We're Digital Signage
                                    </h3>
                                    <h3 className='mb-0  text-center '>
                                        Software Expert!
                                    </h3>
                                </div>
                                <p className=''>
                                    We excel in digital signage software solutions, providing expertise in design,
                                </p>
                                <p className=''>
                                    implementation, and management. Tailored to diverse needs, our services ensure
                                </p>
                                <p className=''>
                                    impactful and seamless integration for dynamic display solutions.
                                </p>

                                <div className="mt-4 text-center ">
                                    <a href="#" target="_blank"><button className=" py-3 px-4 outline-button red-button-outline text-uppercase"><span><h6 className='mb-0'>schedule my demo</h6></span></button></a>
                                </div>
                            </div>

                            <div className='py-lg-5 py-0  about-text d-block d-lg-none'>
                                <div className='mb-2'>
                                    <h3 className='mb-0  text-center '>
                                        We're Digital Signage Software Expert!
                                    </h3>
                                </div>
                                <p className='text-center'>
                                    We excel in digital signage software solutions, providing expertise in design
                                    implementation, and management. Tailored to diverse needs, our services ensure
                                    impactful and seamless integration for dynamic display solutions.
                                </p>

                                <div className="mt-4 text-center ">
                                    <a href="#" target="_blank"><button className=" py-3 px-4 outline-button red-button-outline text-uppercase"><span><h6 className='mb-0'>schedule my demo</h6></span></button></a>
                                </div>
                            </div>
                        </Col>
                        <Col lg="2" md="2" className='py-2' data-aos="zoom-in"></Col>
                    </Row>
                </div >
            </section>
        </>
    )
}
export default About