
import React from "react";
import ReactDOM from "react-dom";
import Routes from "routes";

import "assets/css/nucleo-icons.css";
import "assets/scss/styles.scss?v=1.2.0";
import "assets/demo/demo.css";


ReactDOM.render(
  <Routes />,
  document.getElementById("root")
);
