import React, { useState } from 'react'
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa'
import { Row, Col, Container } from 'reactstrap'
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai"


const FAQ = () => {

    const [active, setIsActive] = useState(null)

    const toggle = (i) => {
        if (active === i) {
            return setIsActive(null)
        }
        setIsActive(i)
    }
    const data = [{
        question: 'What is digital signage software?',
        answer:<div className="mb-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</div>

    },
    {
        question: 'How does digital signage software work?',
        answer:<div className="mb-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</div>
    },
    {
        question: 'What features should I look for in digital signage software?',
        answer:<div className="mb-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</div>

    },
    {
        question: 'Can I customize content with digital signage software?',
        answer:<div className="mb-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</div>
    },
    {
        question: 'Is digital signage software compatible with different types of displays?',
        answer:<div className="mb-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</div >
    },
    {
        question: 'How easy is it to update content using digital signage software?',
        answer: <div className="mb-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</div>
    },
    {
        question: ' What kind of support is available for troubleshooting issues with digital signage software?',
        answer: <div className="mb-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</div>
    },
    {
        question: ' Can I schedule content to display at specific times with digital signage software?',
        answer: <div className="mb-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</div>
    }]

    return (
        <div className=' faq-bg my-5 py-5' >
            <Container >
                <section className=' faq-section '>
                    <div className=" faq-text mx-4" id='faqs'>
                        <h3 className=' text-center pb-4 mb-0 mt-4 mt-md-0 '>Frequently asked questions</h3>
                        <Row>
                            <Col lg="1"></Col>
                            <Col lg="10">
                                {data.map((item, i) => (
                                    <div className="mt-4 faq-content p-3" data-aos="fade-down-left" >
                                        <div className=" d-flex justify-content-between align-items-center  " onClick={() => toggle(i)}>
                                            <h5 className=" mb-0 text-dark">
                                                {item.question}
                                            </h5>
                                            {active === i ? <AiOutlineMinus color="#DE2729" size="30" className="img-fluid cursor pointer faq-control" /> : <AiOutlinePlus color="#DE2729" size="30" className="img-fluid cursor pointer faq-control" />}
                                        </div>
                                        <div className={`faq-ans w-100 ${active === i ? 'content show pt-3' : 'content'}`}>
                                            <p className=" mb-0 text-dark">
                                                {item.answer}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </Col>
                            <Col lg="1"></Col>
                        </Row>
                    </div>

                </section>
            </Container>
        </div>
    )
}

export default FAQ